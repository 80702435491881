// app global css in SCSS form

.maxHeightImg {
    max-height: 120px;
}

.heightImg {
    height: 150px !important;
}

@media only screen and (max-width: 768px){
    .heightImg {
        height: 120px !important;
    }
    .maxHeightImg {
        max-height: 90px;
    }
}

.break-word {
    display: block;
    width: 100%;
    overflow-wrap: break-word;
}

.bg-img {
  background-color: #3C3C43;
}

input, select, .q-field__control, textarea {
  background: #f9f9f9;
}

.q-skeleton:before {
  content: "\a0";
}
